import { graphql } from "gatsby"
import React from "react"
import CustomMenuSection from "../components/custom_menu_section"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CustomMenuPage = props => {
  const { data, path, location } = props

  const menu = data.prismicCustomMenu?.data

  return (
    <Layout path={path} location={location}>
      <SEO title="Menu" />
      <div className="section not-that-much-margin-on-top">
        <div className="image-section">
          {/* {menu.head_image.localFile && (
            <div className="image-container">
              <Img fluid={menu.head_image.localFile.childImageSharp.fluid} />
            </div>
          )} */}
        </div>
        <div className="container">
          <h1 className="custom dark">{menu?.title}</h1>
        </div>
      </div>
      {/* {menu.sections.map((section, index) => (
          <MenuSection
            key={index}
            menu={section.section.document[0].data}
            inverted={index % 2 === 1}
          />
        ))} */}

      <div style={{ position: "relative" }}>
        {menu?.sections?.map((sectionDocument, index) => {
          const section = sectionDocument?.section?.document[0]?.data
          return <CustomMenuSection key={index} section={section} />
        })}
      </div>
      {/* <MenuSection menu={antipasti} inverted={false} /> */}
      <a
        href="http://m.me/187420957968844"
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <div className="custom_menu_cta">
          {menu?.contact_cta && <p>{menu?.contact_cta}</p>}
          <svg
            width="85"
            height="16"
            viewBox="0 0 85 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M74.4574 0L72.5992 1.41L79.9527 7H0V9H79.9527L72.5992 14.59L74.4574 16L85 8L74.4574 0Z"
              fill="#e46f4d"
            />
          </svg>
        </div>
      </a>
    </Layout>
  )
}

export default CustomMenuPage

export const query = graphql`
  query($slug: String!, $localeRegex: String) {
    # prismicCustomMenu(slugs: { eq: $slug }, lang: { regex: $localeRegex }) {
    prismicCustomMenu(
      data: { slug: { eq: $slug } }
      lang: { regex: $localeRegex }
    ) {
      data {
        title
        # head_image {
        #   localFile {
        #     childImageSharp {
        #       fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
        #         ...GatsbyImageSharpFluid_withWebp
        #       }
        #     }
        #   }
        # }
        sections {
          section {
            document {
              data {
                section_title
                first_subsection_title
                # second_subsection_title
                first_subsection_dishes {
                  dish_name
                  dish_description
                  dish_price
                }
                # second_subsection_dishes {
                #   dish_name
                #   dish_description
                #   dish_price
                # }
              }
            }
          }
        }
        contact_cta
      }
    }
  }
`
